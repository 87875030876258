// vendor.js
//
// All Javascript and CSS from vendor sources, such as Bootstrap, etc
// TODO: eventually merge this into vendor.js

// Import all Sass/CSS files to be compiled into base.css
import './vendor.scss';

// Enable hot module reloading via Parcel in dev
if (module.hot) {
  module.hot.accept();
}

// Initialize Bootstrap elements
// import 'bootstrap';

// Alerts
import 'bootstrap/js/src/alert.js';

// Buttons
import 'bootstrap/js/src/button.js';

// Carousel
// import 'bootstrap/js/src/carousel.js';

// Collapse
import 'bootstrap/js/src/collapse.js';
// import Collapse from 'bootstrap/js/src/collapse.js';
// const collapseElementList = document.querySelectorAll('.collapse');
// const collapseList = [...collapseElementList].map(collapseEl => new Collapse(collapseEl));

// Dropdown
// import 'bootstrap/js/src/dropdown.js';

// Modal
// import 'bootstrap/js/src/modal.js';

// Offcanvas
// import 'bootstrap/js/src/offcanvas.js';

// Popover
// import 'bootstrap/js/src/popover.js';

// Scrollspy
// import 'bootstrap/js/src/scrollspy.js';

// Tab
// import 'bootstrap/js/src/tab.js';

// Toast
// import 'bootstrap/js/src/toast.js';

// Enable tooltips
import Tooltip from 'bootstrap/js/src/tooltip.js';
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

import NiceSelect from "nice-select2/src/js/nice-select2.js";
const unitInputList = document.querySelectorAll("#id_unit");
const unitList = [...unitInputList].map(unitInputEl => new NiceSelect(unitInputEl, {searchable: true}));
